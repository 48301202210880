import { createSelector } from 'reselect';

export const usersModuleSelector = state => state.user;

// export const selectListedUsers = createSelector(
//   usersModuleSelector,
//   subState => subState.get('users').toJS(),
// );
//
// export const selectListedUsersArray = createSelector(
//   selectListedUsers,
//   subState => Object.values(subState),
// );

export const selectUserDataLoading = createSelector(
    usersModuleSelector,
    subState => subState.get('loading')
);

export const selectListedGroupsArray = createSelector(
    usersModuleSelector,
    subState => subState.get('groups'),
);

