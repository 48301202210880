import { takeEvery, put, call } from 'redux-saga/effects';
import { LIST_REQUEST } from '../constants/places';
import {
  listFailure,
  listSuccess,
} from '../actions/places';
import {getAllPlacesGroups} from "../../database/events";

function* listWatcher() {
  try {
    const places = yield call(getAllPlacesGroups);
    console.log(places);
    yield put(listSuccess(places));
  } catch(e) {
    yield put(listFailure(e));
  }
}

export default function* placesSaga() {
  yield takeEvery(LIST_REQUEST, listWatcher);
}
