export const LIST_REQUEST = 'MESSAGES/LIST/REQUEST';
export const LIST_SUCCESS = 'MESSAGES/LIST/SUCCESS';
export const LIST_FAILURE = 'MESSAGES/LIST/FAILURE';

export const GET_CHAT_REQUEST = 'MESSAGES/GET_CHAT/REQUEST';
export const GET_CHAT_SUCCESS = 'MESSAGES/GET_CHAT/SUCCESS';
export const GET_CHAT_FAILURE = 'MESSAGES/GET_CHAT/FAILURE';

export const GET_MESSAGE_REQUEST = 'MESSAGES/GET_MESSAGE/REQUEST';

export const SEND_MESSAGE_REQUEST = 'MESSAGES/SEND_MESSAGE/REQUEST';
export const SEND_MESSAGE_SUCCESS = 'MESSAGES/SEND_MESSAGE/SUCCESS';
export const SEND_MESSAGE_FAILURE = 'MESSAGES/SEND_MESSAGE/FAILURE';

export const SELECT_MESSAGE = 'MESSAGES/SELECT';
