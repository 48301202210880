import {
  CHANGE_STATUS_REQUEST,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_FAILURE,
  LIST_FAILURE,
  LIST_REQUEST,
  LIST_SUCCESS, ENTER_GROUP_REQUEST, ENTER_GROUP_SUCCESS, ENTER_GROUP_FAILURE, UPDATE_GROUP_DATA,
} from '../constants/users';

export const enterGroupRequest = payload => ({
  type: ENTER_GROUP_REQUEST,
  payload,
});

export const enterGroupSuccess = () => ({
  type: ENTER_GROUP_SUCCESS,
});

export const enterGroupFailure = payload => ({
  type: ENTER_GROUP_FAILURE,
  payload,
});

export const listRequest = () => ({
  type: LIST_REQUEST,
});

export const listSuccess = payload => ({
  type: LIST_SUCCESS,
  payload,
});

export const listFailure = payload => ({
  type: LIST_FAILURE,
  payload,
});

export const changeStatusRequest = (userId, toStatus) => ({
  type: CHANGE_STATUS_REQUEST,
  payload: {
    userId,
    toStatus,
  },
});

export const changeStatusSuccess = payload => ({
  type: CHANGE_STATUS_SUCCESS,
  payload,
});

export const changeStatusFailure = payload => ({
  type: CHANGE_STATUS_FAILURE,
  payload,
});

export const updateGroupData = payload => ({
  type: UPDATE_GROUP_DATA,
  payload,
});
