import { privateMessagesCollection, usersCollection } from './collections';
import firebase from "firebase/compat/app";
import { randomToken } from "../utils/RandomToken";
import { getUserProfile } from "./users";

export const sendMessage = async (foreignUserId, token, message) => {
    if ((message && message.trim())) {
        const { uid } = firebase.auth().currentUser;
        const userData = await getUserProfile(uid);
        const { username } = userData;

        if (username) {
            const data = {
                createdAt: new Date(),
                message,
                username,
                userId: uid,
                foreignUserId
            };

            const lastMessage = {
                createdAt: new Date(),
                username,
                message,
                userId: uid,
            };

            if (token) {
                const privateMessageRef = privateMessagesCollection().doc(token);
                const batch = firebase.firestore().batch();
                batch.update(privateMessageRef, {lastMessage});
                batch.set(privateMessageRef.collection('messages').doc(), data);
                return await batch.commit()
            } else {
                const newToken = randomToken(foreignUserId + firebase.auth().currentUser.uid, 28);
                const tokenForUser = await getTokenForUser(foreignUserId);
                if (newToken && !tokenForUser) {
                    const privateMessageRef = privateMessagesCollection().doc(newToken);
                    const batch = firebase.firestore().batch();
                    batch.update(
                        usersCollection()
                            .doc(uid).collection('openPrivateMessages')
                            .doc(foreignUserId), {
                            token: newToken,
                        },
                    );
                    batch.set(
                        usersCollection()
                            .doc(foreignUserId).collection('openPrivateMessages')
                            .doc(uid), {
                            username,
                            createdAt: new Date(),
                            token: newToken,
                        },
                    );
                    batch.set(privateMessageRef, {
                        lastMessage,
                        createdAt: new Date(),
                    });
                    batch.set(privateMessageRef.collection('messages').doc(), data);
                    return await batch.commit().then(() => Promise.resolve({result: privateMessageRef.id}));
                }
            }
        }
    }
    return Promise.reject()
};

export const getPrivateMessage = async (token) => {
    if (token) {
        const privateMessagesRef = await privateMessagesCollection()
            .doc(token)
            .get();
        return {
            id: privateMessagesRef.id,
            ...privateMessagesRef.data(),
        };
    }
    return {};
};

export const startMessagesSnapshot = (connectionId, onChange) => {
    const unsubscribe = privateMessagesCollection()
        .doc(connectionId)
        .collection('messages')
        .orderBy('createdAt', 'desc')
        .onSnapshot((snapshot) => {
            const allMessages = snapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
                createdAt: doc.data()
                    .createdAt
                    .toDate(),
            }));
            onChange(allMessages)
        });
    return () => unsubscribe;
};

export const getTokenForUser = async (foreignUserId) => {
    const uid = firebase.auth().currentUser.uid;
    const userRef = await usersCollection()
        .doc(uid)
        .collection('openPrivateMessages')
        .doc(foreignUserId)
        .get();
    return userRef.data() ? userRef.data().token : undefined;
};
