import { eventsCollection } from './collections';
import { createAndInsertUserInGroup } from "./groups";
import firebase from "firebase/compat/app";
import { GroupType } from "../states/constants/groups";

export const getAllEvents = async () => {
  const eventsRef = await eventsCollection().get();
  return eventsRef.docs.map((doc) => doc.data());
};

export const updateEvent = async (eventId, field) => {
  return await eventsCollection().doc(eventId).update({ ...field });
};

export const createEvent = async (eventData) => {
  const { uid } = firebase.auth().currentUser;
  const eventRef = await eventsCollection().doc();

  const parentId = eventRef.id;
  const groupData = {
    name: eventData.title,
    category: GroupType.EVENT,
    data: eventData,
  };

  return eventRef.set({
    data: eventData,
    category: GroupType.EVENT,
    createdAt: new Date(),
    createdBy: uid,
  }).then(() =>
     createAndInsertUserInGroup(parentId, groupData).then(
        () => Promise.resolve({ parentId, groupData })
    )
  );
};

export const getEvents = async (placesIds) => {
  const promises = placesIds.map(async (id) => {
    const eventsRef = await eventsCollection()
        .where('data.placeId', '==', id)
        .where('data.finalDate', '>=', new Date())
        .get();
    return eventsRef.docs
        ? eventsRef.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) : [];
  });
  return (await Promise.all(promises)).flat();
};
