import { takeEvery, put, call } from 'redux-saga/effects';
import { LIST_REQUEST } from '../constants/events';
import {
  listFailure,
  listSuccess,
} from '../actions/events';
import { getAllEvents, getAllEventsGroups } from '../../database/events';

function* listWatcher() {
  try {
    const events = yield call(getAllEventsGroups);
    console.log(events);
    yield put(listSuccess(events));
} catch(e) {
    console.log(e)
    yield put(listFailure(e));
  }
};

export default function* eventSaga() {
  yield takeEvery(LIST_REQUEST, listWatcher);
}
