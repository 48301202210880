import { createSelector } from 'reselect';

export const eventModuleSelector = state => state.event;

export const selectListedEvent = createSelector(
  eventModuleSelector,
  subState => subState.get('events'),
);

export const selectEventLoading = createSelector(
  eventModuleSelector,
  subState => subState.get('loading'),
);

export const selectEventError = createSelector(
  eventModuleSelector,
  subState => subState.get('error'),
);

