import { takeLatest, put, call } from 'redux-saga/effects';
import {
  CREATE_CHAT_REQUEST,
  CLOSE_CHAT_REQUEST,
  GET_CHAT_REQUEST,
  LIST_REQUEST,
  SEND_MESSAGE_REQUEST,
} from '../constants/chats';
import {
  closeChatFailure,
  closeChatSuccess,
  createChatFailure,
  createChatSuccess,
  getChatFailure,
  getChatRequest,
  getChatSuccess,
  listFailure,
  listRequest,
  listSuccess,
  sendMessageFailure,
  sendMessageSuccess,
} from '../actions/chats';

function* listWatcher() {
  try {
    // const response = yield call(axios.get, 'chats');

    yield put(listSuccess(response.data));
  } catch (e) {
    yield put(listFailure(e));
  }
}

function* getChatWatcher() {
  try {
    const response = yield call(axios.get, 'chats/active');

    yield put(getChatSuccess(response.data));
  } catch (e) {
    yield put(getChatFailure(e));
  }
}

function* sendMessageWatcher({ payload }) {
  if (!payload.message) {
    return;
  }

  try {
    // yield call(axios.post, `chats/${payload.chat.id}/message`, { message: payload.message });

    yield put(sendMessageSuccess());
  } catch (e) {
    yield put(sendMessageFailure(e));
  }
}

function* closeChatWatcher({ payload }) {
  try {
    // yield call(axios.post, `chats/${payload.chat.id}/close`);

    yield put(closeChatSuccess());
    yield put(listRequest());
  } catch (e) {
    yield put(closeChatFailure(e));
  }
}

function* createChatWatcher() {
  try {
    yield call(axios.post, 'chats/new');

    yield put(createChatSuccess());
    yield put(getChatRequest());
  } catch (e) {
    yield put(createChatFailure(e));
  }
}

export default function* chatsSaga() {
  yield takeLatest(LIST_REQUEST, listWatcher);
  yield takeLatest(CREATE_CHAT_REQUEST, createChatWatcher);
  yield takeLatest(GET_CHAT_REQUEST, getChatWatcher);
  yield takeLatest(SEND_MESSAGE_REQUEST, sendMessageWatcher);
  yield takeLatest(CLOSE_CHAT_REQUEST, closeChatWatcher);

  yield put(getChatRequest());
}
