import { createSelector } from 'reselect';

export const authModuleSelector = state => state.auth;


export const selectDisplayName = createSelector(
  authModuleSelector,
  subState => subState.get('primeiroNome'),
);

export const selectAuthError = createSelector(
  authModuleSelector,
  subState => subState.get('error'),
);

export const selectUserId = createSelector(
  authModuleSelector,
  subState => subState.get('id'),
);

export const selectRole = createSelector(
  authModuleSelector,
  subState => subState.get('role'),
);


export const selectAvatarURL = createSelector(
  authModuleSelector,
  subState => subState.get('avatarURL'),
);

export const selectIsAuthenticated = createSelector(
  authModuleSelector,
  subState => subState.get('isAuthenticated'),
);
