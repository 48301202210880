import {
  LIST_SUCCESS,
  LIST_REQUEST,
  LIST_FAILURE,
} from '../constants/events';
import { fromJS } from 'immutable';

const initialState = fromJS({
  loading: true,
  error: null,
  events: [],
});


const eventReducer = (state = initialState, action) => {
  switch (action.type) {
      case LIST_REQUEST: 
        return state.set('loading', true);
      case LIST_SUCCESS: 
        return state
          .set('loading', false)
          .set('events', action.payload);
      case LIST_FAILURE:
        return state.set('error', action.payload)
    default:
      return state;
  }
};

export default eventReducer;
