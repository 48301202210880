import { fromJS, Map } from 'immutable';
import {
  CLOSE_CHAT_SUCCESS,
  GET_CHAT_SUCCESS,
  LIST_SUCCESS,
  SELECT_CHAT,
} from '../constants/chats';

const initialState = fromJS({
  chats: [],
  openChats: [],
  currentId: null,
  selectedId: null,
});

const toMap = arr => Map().withMutations((map) => {
  arr.forEach(chat => map.set(chat.id, fromJS(chat)));
});

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_CHAT:
      return state.set('selectedId', action.payload.chatId);
    case LIST_SUCCESS:
      return state
        .set('chats', toMap(action.payload.filter(chat => !!chat.professional)))
        .set('openChats', toMap(action.payload.filter(chat => !chat.professional)));
    case GET_CHAT_SUCCESS:
      if (action.payload.error) {
        return state.set('currentId', null);
      }
      return state
        .set('currentId', action.payload.id)
        .setIn(['chats', action.payload.id], fromJS(action.payload));
    case CLOSE_CHAT_SUCCESS:
      return state.set('currentId', null);
    default:
      return state;
  }
};

export default chatReducer;
