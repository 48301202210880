const orderByWithLastMessage = (messages) => {
  const groupsWithLastMessage = messages.filter(
    (message) => Boolean(message.info && message.info.lastMessage),
  );
  return groupsWithLastMessage.sort((previousItem, nextItem) => (
    nextItem.info.lastMessage.createdAt < previousItem.info.lastMessage.createdAt ? -1 : 1
  ));
};

const orderByWithoutLastMessage = (messages) => {
  const groupsWithoutLastMessage = messages.filter(
    (message) => (message.info && message.info.lastMessage === undefined),
  );
  return groupsWithoutLastMessage.sort(
    (previousItem, nextItem) => (nextItem.createdAt < previousItem.createdAt ? -1 : 1),
  );
};

export const formatMessages = (messages) => {
  const withLastMessage = orderByWithLastMessage(messages);
  const withoutLastMessage = orderByWithoutLastMessage(messages);
  return withLastMessage.concat(withoutLastMessage);
};
