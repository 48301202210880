import {
  GET_CHAT_FAILURE,
  GET_CHAT_REQUEST,
  GET_CHAT_SUCCESS,
  CREATE_CHAT_FAILURE,
  CREATE_CHAT_REQUEST,
  CREATE_CHAT_SUCCESS,
  LIST_FAILURE,
  LIST_REQUEST,
  LIST_SUCCESS,
  SEND_MESSAGE_FAILURE,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  CLOSE_CHAT_FAILURE,
  CLOSE_CHAT_REQUEST,
  CLOSE_CHAT_SUCCESS,
  SELECT_CHAT,
} from '../constants/chats';

export const listRequest = () => ({
  type: LIST_REQUEST,
});

export const listSuccess = payload => ({
  type: LIST_SUCCESS,
  payload,
});

export const listFailure = payload => ({
  type: LIST_FAILURE,
  payload,
});

export const createChatRequest = () => ({
  type: CREATE_CHAT_REQUEST,
});

export const createChatSuccess = payload => ({
  type: CREATE_CHAT_SUCCESS,
  payload,
});

export const createChatFailure = payload => ({
  type: CREATE_CHAT_FAILURE,
  payload,
});

export const getChatRequest = () => ({
  type: GET_CHAT_REQUEST,
});

export const getChatSuccess = payload => ({
  type: GET_CHAT_SUCCESS,
  payload,
});

export const getChatFailure = payload => ({
  type: GET_CHAT_FAILURE,
  payload,
});

export const sendMessageRequest = (chat, message) => ({
  type: SEND_MESSAGE_REQUEST,
  payload: {
    chat,
    message,
  },
});

export const sendMessageSuccess = payload => ({
  type: SEND_MESSAGE_SUCCESS,
  payload,
});

export const sendMessageFailure = payload => ({
  type: SEND_MESSAGE_FAILURE,
  payload,
});

export const closeChatRequest = chat => ({
  type: CLOSE_CHAT_REQUEST,
  payload: {
    chat,
  },
});

export const closeChatSuccess = payload => ({
  type: CLOSE_CHAT_SUCCESS,
  payload,
});

export const closeChatFailure = payload => ({
  type: CLOSE_CHAT_FAILURE,
  payload,
});

export const selectChat = chat => ({
  type: SELECT_CHAT,
  payload: {
    chatId: (typeof chat === 'object') ? chat.id : Number(chat),
  },
});

