export const LIST_REQUEST = 'CHATS/LIST/REQUEST';
export const LIST_SUCCESS = 'CHATS/LIST/SUCCESS';
export const LIST_FAILURE = 'CHATS/LIST/FAILURE';

export const GET_CHAT_REQUEST = 'CHATS/GET_CHAT/REQUEST';
export const GET_CHAT_SUCCESS = 'CHATS/GET_CHAT/SUCCESS';
export const GET_CHAT_FAILURE = 'CHATS/GET_CHAT/FAILURE';

export const CREATE_CHAT_REQUEST = 'CHATS/CREATE_CHAT/REQUEST';
export const CREATE_CHAT_SUCCESS = 'CHATS/CREATE_CHAT/SUCCESS';
export const CREATE_CHAT_FAILURE = 'CHATS/CREATE_CHAT/FAILURE';

export const SEND_MESSAGE_REQUEST = 'CHATS/SEND_MESSAGE/REQUEST';
export const SEND_MESSAGE_SUCCESS = 'CHATS/SEND_MESSAGE/SUCCESS';
export const SEND_MESSAGE_FAILURE = 'CHATS/SEND_MESSAGE/FAILURE';

export const CLOSE_CHAT_REQUEST = 'CHATS/CLOSE_CHAT/REQUEST';
export const CLOSE_CHAT_SUCCESS = 'CHATS/CLOSE_CHAT/SUCCESS';
export const CLOSE_CHAT_FAILURE = 'CHATS/CLOSE_CHAT/FAILURE';

export const SELECT_CHAT = 'CHATS/SELECT';
