import { createSelector } from 'reselect';

export const placeModuleSelector = state => state.place;

export const selectListedPlaces = createSelector(
  placeModuleSelector,
  subState => subState.get('places')
);

export const selectPlacesLoading = createSelector(
  placeModuleSelector,
  subState => subState.get('loading')
);
