import { spawn } from "redux-saga/effects";
import PlaceRootSaga from './places';
import EventsRootSaga from './events';
import authRootSaga from './auth';
import usersRootSaga from './users';
import chatsRootSaga from './chats';

export default function* rootSaga() {
    yield spawn(PlaceRootSaga);
    yield spawn(EventsRootSaga);
    yield spawn(authRootSaga);
    yield spawn(usersRootSaga);
    yield spawn(chatsRootSaga);
};
