import { groupsCollection, usersCollection } from './collections';
import firebase from "firebase/compat/app";
import { randomToken } from "../utils/RandomToken";
import { pickBy } from "lodash";
import { GroupType } from "../states/constants/groups";
import { getUserProfile } from "./users";

export const getGroup = async (groupId) => {
    const doc = await groupsCollection()
        .doc(groupId)
        .get();
    return groupDataParse(doc)
};

export const updateGroup = async (groupId, field) => {
    return await groupsCollection().doc(groupId).update({ ...field });
};

export const getGroupByExternalId = async (externalId) => {
    const groupRef = await groupsCollection()
        .where('externalId', '==', externalId)
        .get();

    return groupRef.docs.length > 0
        ? Promise.resolve({ result: groupDataParse(groupRef.docs.shift()) })
        : Promise.reject();
};

const groupDataParse = (doc) => {
    const data = doc.data();
    const parentId = data && (data.parentId || data.localOrEventId);
    return {
        id: doc.id,
        parentId,
        ...data
    }
};

export const checkExternalIdWasUsed = async (externalId) => {
    const groupData = await getGroupByExternalId(externalId)
        .then((value) => {
            return value.result
        }).catch(() => {
            return null
        });
    return groupData && Object.keys(groupData).length > 0;
};

export const generateExternalId = async (groupId) => {
    const externalId = randomToken(groupId, 10);
    return await checkExternalIdWasUsed(externalId) ?
        Promise.reject() :
        await groupsCollection()
            .doc(groupId)
            .update({ externalId })
            .then(() => externalId)
};

export const groupIdByParentId = async (parentId) => {
    if (!parentId) {
      return false;
    }
    try {
      const groupRef = await groupsCollection()
        .where('parentId', '==', parentId)
        .limit(1)
        .get({ source: 'server' });
  
      if (groupRef.docs.length === 0) {
        return await groupIdByLocalOrEventId(parentId);
      }
      const data = groupDataParse(groupRef.docs.shift());
      if (GroupType.SUBGROUP === data.category) {
        return false;
      }
      return data.id;
    } catch (error) {
      console.error('Error fetching group by parent ID:', error);
      throw new Error('Unable to fetch group by parent ID');
    }
  };

const groupIdByLocalOrEventId = async (localOrEventId) => {
    const groupRef = await groupsCollection()
        .where('localOrEventId', '==', localOrEventId)
        .limit(1)
        .get({ source: 'server' });

    if (groupRef.docs.length === 0) {
        return false
    }
    return groupDataParse(groupRef.docs.shift()).id;
};

export const createGroup = async (parentId, groupData) => {
    const batch = firebase.firestore().batch();
    let groupRef = null;

    const additionalData = {};
    switch (groupData.category) {
        case GroupType.EVENT:
            groupRef = await groupsCollection().doc();
            additionalData.eventInfo = {
                desc: groupData.data.description,
                placeId: groupData.data.placeId,
                initialDate: groupData.data.initialDate,
                finalDate: groupData.data.finalDate,
            };
            break;
        case GroupType.SUBGROUP:
            groupRef = await groupsCollection().doc();
            const parentSubGroupRef = groupsCollection()
                .doc(parentId)
                .collection('subGroups')
                .doc(groupRef.id);

            batch.set(parentSubGroupRef, {
                name: groupData.name,
                createdAt: new Date(),
            })
            break;
        default:
            groupRef = await groupsCollection().doc(parentId);
            break;
    }

    batch.set(groupRef, pickBy({
        parentId,
        name: groupData.name,
        description: groupData.description,
        password: groupData.password,
        private: groupData.private,
        category: groupData.category,
        createdAt: new Date(),
        ...additionalData,
    }));

    try {
        await batch.commit();
        return { id: groupRef.id };
    } catch (error) {
        console.error('Error creating group:', error);
        throw error;
    }
};

export const insertUserIntoGroup = async (groupId, groupParentId, groupData, creatingGroup) => {
    if (!firebase.auth().currentUser) return Promise.reject();
    const { uid, displayName } = firebase.auth().currentUser;

    const batch = firebase.firestore().batch();
    const groupRef = await groupsCollection().doc(groupId);
    const userGroupRef = groupRef
        .collection('users')
        .doc(uid);

    if (
        creatingGroup &&
        (groupData.category === GroupType.EVENT || groupData.category === GroupType.SUBGROUP)
    ) {
        batch.set(userGroupRef, {
            createdAt: new Date(),
            name: displayName,
            admin: true,
            owner: true,
        });
    } else {
        batch.set(userGroupRef, {
            createdAt: new Date(),
            name: displayName,
        });
    }

    const userRef = usersCollection()
        .doc(uid)
        .collection('groups')
        .doc(groupId);

    batch.set(userRef, {
        createdAt: new Date(),
        parentId: groupParentId,
    });

    return await batch.commit().then(
        () => Promise.resolve({ id: groupRef.id })
    );
};

export const createAndInsertUserInGroup = async (
    parentId,
    groupData,
    groupId
) => !!groupId ?
    await insertUserIntoGroup(groupId, parentId, groupData) :
    await createGroup(parentId, groupData).then(({ id }) => 
        insertUserIntoGroup(id, parentId, groupData, true))

export const groupExit = async (groupId, userId) => {
    const batch = firebase.firestore().batch();

    const userRef = usersCollection()
        .doc(userId)
        .collection('groups')
        .doc(groupId);
    batch.delete(userRef);

    const groupRef = groupsCollection()
        .doc(groupId)
        .collection('users')
        .doc(userId);
    batch.delete(groupRef);

    return await batch.commit();
};

export const checkUserIsAdmin = async (groupId, userId) => {
    const user = await groupsCollection()
        .doc(groupId)
        .collection('users')
        .doc(userId)
        .get();
    return user.data() && !!user.data().admin;
};

export const checkUserIsGroupOwner = async (groupId, userId) => {
    const user = await groupsCollection()
        .doc(groupId)
        .collection('users')
        .doc(userId)
        .get();
    return user.data() && !!user.data().owner;
};

export const startGroupUsersSnapshot = (groupId, callback) => {
    groupsCollection()
        .doc(groupId)
        .collection('users')
        .onSnapshot((snapshot) => {
            const fetchUsersData = async () => await Promise.all(
                snapshot.docs.map(async (document) => {
                    const userData = await getUserProfile(document.id);
                    return {
                        id: document.id,
                        admin: document.get("admin"),
                        username: userData.username,
                        name: userData.name,
                        avatarUrl: userData.avatarUrl,
                        professions: userData.professions
                    };
                }),
            );
            fetchUsersData().then(callback)
        });
};

export const setAdmin = async (userId, groupId, admin) => {
    return await groupsCollection()
    .doc(groupId)
    .collection('users')
    .doc(userId)
    .update({ admin });
};

export const subGroups = async (groupId) => {
    const subGroupsRef = await groupsCollection()
        .doc(groupId)
        .collection('subGroups')
        .get();

    return subGroupsRef.docs.map((doc) => groupDataParse(doc));
};
