import { combineReducers } from 'redux';
import placeReducer from "./places";
import eventReducer from './events';
import authReducer from './auth';
import chatsReducer from './chats';
import usersReducer from './users';

export default combineReducers({
  auth: authReducer,
  place: placeReducer,
  event: eventReducer,
  chats: chatsReducer,
  user: usersReducer,
});
