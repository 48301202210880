import { createSelector } from 'reselect';

export const sessionModuleSelector = state => state.messages;

export const selectListedMessages = createSelector(
  sessionModuleSelector,
  subState => subState
    .get('messages')
    .valueSeq()
    .filter(val => !!val)
    .toJS(),
);

export const selectCurrentMessageId = createSelector(
  sessionModuleSelector,
  subState => subState.get('currentId'),
);

export const selectCurrentMessage = createSelector(
  sessionModuleSelector,
  selectCurrentMessageId,
  (subState, id) => {
    const immMessage = subState.getIn(['messages', id]);

    return immMessage ? immMessage.toJS() : null;
  },
);

export const selectSelectedMessageId = createSelector(
  sessionModuleSelector,
  subState => subState.get('selectedId'),
);

export const selectSelectedMessage = createSelector(
  sessionModuleSelector,
  selectSelectedMessageId,
  (subState, id) => {
    const immMessage = subState.getIn(['messages', id]);

    return immMessage ? immMessage.toJS() : null;
  },
);

export const selectHasActiveMessage = createSelector(
  selectCurrentMessage,
  subState => !!subState,
);
