import {
  LIST_FAILURE,
  LIST_REQUEST,
  LIST_SUCCESS,
  ADD_REQUEST,
  ADD_SUCCESS,
  ADD_FAILURE,
} from '../constants/products';

export const listRequest = () => ({
  type: LIST_REQUEST,
});

export const listSuccess = payload => ({
  type: LIST_SUCCESS,
  payload,
});

export const listFailure = payload => ({
  type: LIST_FAILURE,
  payload,
});

export const addRequest = payload => ({
  type: ADD_REQUEST,
  payload,
});
