import {
  GET_CHAT_FAILURE,
  GET_CHAT_REQUEST,
  GET_CHAT_SUCCESS,
  LIST_FAILURE,
  LIST_REQUEST,
  LIST_SUCCESS,
  GET_MESSAGE_REQUEST,
  SEND_MESSAGE_FAILURE,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SELECT_MESSAGE,
} from '../constants/messages';

export const selectMessage = message => ({
  type: SELECT_MESSAGE,
  payload: {
    messageId: (typeof message === 'object') ? message.id : Number(message),
  },
});

export const listRequest = () => ({
  type: LIST_REQUEST,
});

export const listSuccess = payload => ({
  type: LIST_SUCCESS,
  payload,
});

export const listFailure = payload => ({
  type: LIST_FAILURE,
  payload,
});

export const getChatRequest = () => ({
  type: GET_CHAT_REQUEST,
});

export const getChatSuccess = payload => ({
  type: GET_CHAT_SUCCESS,
  payload,
});

export const getChatFailure = payload => ({
  type: GET_CHAT_FAILURE,
  payload,
});


export const messagesRequest = (chat, message) => ({
  type: GET_MESSAGE_REQUEST,
  payload: {
    chat,
    message,
  },
});

export const messageRequest = (chat, message) => ({
  type: SEND_MESSAGE_REQUEST,
  payload: {
    chat,
    message,
  },
});

export const messageSuccess = payload => ({
  type: SEND_MESSAGE_SUCCESS,
  payload,
});

export const messageFailure = payload => ({
  type: SEND_MESSAGE_FAILURE,
  payload,
});
