export const LIST_REQUEST = 'USERS/LIST/REQUEST';
export const LIST_SUCCESS = 'USERS/LIST/SUCCESS';
export const LIST_FAILURE = 'USERS/LIST/FAILURE';

export const CHANGE_STATUS_REQUEST = 'USERS/CHANGE_STATUS/REQUEST';
export const CHANGE_STATUS_SUCCESS = 'USERS/CHANGE_STATUS/SUCCESS';
export const CHANGE_STATUS_FAILURE = 'USERS/CHANGE_STATUS/FAILURE';

export const ENTER_GROUP_REQUEST = 'USERS/ENTER_GROUP/REQUEST';
export const ENTER_GROUP_SUCCESS = 'USERS/ENTER_GROUP/SUCCESS';
export const ENTER_GROUP_FAILURE = 'USERS/ENTER_GROUP/FAILURE';

export const UPDATE_GROUP_DATA = 'USERS/UPDATE_GROUP_DATA';
