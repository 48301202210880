import firebase from 'firebase/compat/app';

export const addInfoToFirebase = (info) => {
  database.ref(`/${id}`)
    .set({
    info,
    id
    }).then(function(addInfo) {
      console.log('Add info', addInfo)
    })
};

export const removeInfoFromFirebase = (id) => {
  database.ref(`/${id}`)
    .remove().then(function(removeInfo) {
      console.log('Remove info', removeInfo)
  })
};

export  const getEventFromFirebase = () => {
  docRef.get().then(function (doc) {
    if (doc.exists) {
      console.log("Document data:", doc.data());
    } else {
      console.log("No such document!");
    }
  }).catch(function (error) {
    console.log("Error getting document:", error);
  });
};

function addEvent(event) {
  const newItemRef = database.ref('/events').push();
  return newItemRef.set(event);
}

function addPlace(place) {
  const id = uuid();
  database.collection("places").doc(id).set({
    place
  })
    .then(function() {
      console.log("Document successfully written!");
    })
    .catch(function(error) {
      console.error("Error writing document: ", error);
    });
}

export default firebase;

