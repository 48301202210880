import { fromJS } from 'immutable';
import { LIST_SUCCESS, LIST_REQUEST, LIST_FAILURE } from '../constants/places';

const initialState = fromJS({
  loading: true,
  places: [],
});

const placeReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_SUCCESS:
      return state
        .set('loading', false)
        .set('places', action.payload);
    case LIST_FAILURE:
      return state
        .set('loading', false);
    case LIST_REQUEST:
      return state.set('loading', true);
    default:
      return state;
  }
};

export default placeReducer;
