import {groupsCollection, usersCollection} from './collections';
import firebase from "firebase/compat/app";
import {getTokenForUser, getPrivateMessage} from "./privateMessages";
import {formatMessages} from "./SortMessages";
import {getGroup} from "./groups";

export const getUserProfile = async (id) => {
    const usersRef = await usersCollection().doc(id).get();
    return usersRef.data();
};

export const updateUserProfile = async (id, set) => {
    try {
        await usersCollection().doc(id).update(set);
    } catch (e) {
        console.error(e);
    }
};

export const updateLastOpenGroupChat = async (id, groupId, lastOpenedAt) => {
    return usersCollection()
        .doc(id)
        .collection('groups')
        .doc(groupId)
        .update({ lastOpenedAt })
};

export const updateLastOpenPrivateChat = async (id, foreignUserId, lastOpenedAt) => {
   return usersCollection()
        .doc(id)
        .collection('openPrivateMessages')
        .doc(foreignUserId)
        .update({ lastOpenedAt })
};

export const checkUsernameExists = async (username) => {
    const hasUsername = await usersCollection()
        .where('username', '==', username)
        .get();
    return hasUsername.docs && hasUsername.docs.length > 0;
};

export const updateCurrentUser = async (obj) => {
    const { uid } = firebase.auth().currentUser;
    await usersCollection().doc(uid).update(obj);
};

export const currentUserExists = async () => {
    const { uid } = firebase.auth().currentUser;
    const user = await usersCollection().doc(uid).get();
    return user.data() && user.data().username;
};

export const userIsBusiness = async (userId) => {
    const user = await getUserProfile(userId);
    return user && !!user.business;
};

export const groupPresentIntoUser = async (userId, groupId) => {
    const groupRef = await usersCollection()
        .doc(userId)
        .collection('groups')
        .doc(groupId)
        .get({ source: 'server' });
    return !!groupRef.data();
};

export const addGroupToUser = async (groupId) => {
    const loggedUser = firebase.auth().currentUser;
    if (!loggedUser || !groupId) {
        throw "No data";
    }

    try {
        const batch = firebase.firestore().batch();

        const userDoc = usersCollection().doc(loggedUser.uid).collection('groups').doc(groupId);
        batch.set(userDoc, {
            createdAt: new Date(),
        });

        const groupDoc = groupsCollection().doc(groupId).collection('users').doc(loggedUser.uid);
        batch.set(groupDoc, {
            createdAt: new Date(),
        });

        await batch.commit();
    } catch (e) {
        console.error(e);
    }
};

export const deleteUser = async (userId, groupId) => {
    const batch = firebase.firestore().batch();

    const userRef = usersCollection()
        .doc(userId)
        .collection('groups')
        .doc(groupId);
    batch.delete(userRef);

    const groupRef = groupsCollection()
        .doc(groupId)
        .collection('users')
        .doc(userId);
    batch.delete(groupRef);

    return await batch.commit()
};

export const createUserProfile = async (userId, email) => {
    return await usersCollection().doc(userId).set({
        notifications: { enabled: true },
        email,
        newUser: true,
        createdAt: new Date(),
    });
};

export const startUserSnapshot = (userId, callback) => {
  usersCollection()
      .doc(userId)
      .onSnapshot((doc) => {
        callback(doc.data());
      });
};

export const startPrivateMessageForUser = async (foreignUserId, username) => {
    const token = await getTokenForUser(foreignUserId);
    if (!token) {
        const uid = firebase.auth().currentUser.uid;
        const batch = firebase.firestore().batch();
        batch.set(
            usersCollection()
                .doc(uid).collection('openPrivateMessages')
                .doc(foreignUserId), {
                username: username,
                createdAt: new Date(),
            },
        );
        return batch.commit();
    }
    return Promise.resolve({result: token})
};

export const startGroupsSnapshot = (userId, callback) => {
    usersCollection()
        .doc(userId)
        .collection('groups')
        .onSnapshot((snapshot) => handleNewGroupsSnapshot(snapshot, callback));
};

export const startPrivateChatsSnapshot = (userId, callback) => {
    usersCollection()
        .doc(userId)
        .collection('openPrivateMessages')
        .onSnapshot((snapshot) => handlePrivateChatSnapshot(snapshot, callback));
};

const handlePrivateChatSnapshot = async (snapshot, callback) => {
    const privateChats = await Promise.all(snapshot.docs.map(async (document) => ({
        id: document.id,
        username: document.data().username,
        info: await getPrivateMessage(document.data().token),
        createdAt: document.data().createdAt,
        ...document.data(),
    })));
    callback(formatMessages(privateChats));
};

const handleNewGroupsSnapshot = async (snapshot, callback) => {
    const groups = await Promise.all(snapshot.docs.map(async (document) => ({
        id: document.id,
        info: await getGroup(document.id),
        createdAt: document.data().createdAt,
        ...document.data(),
    })));
    callback(formatMessages(groups));
};
