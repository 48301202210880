import firebase from '../configuration/firebase';

const USERS_COLLECTION_NAME = 'users';
const CATEGORIES_COLLECTION_NAME = 'categories';
const GROUPS_COLLECTION_NAME = 'groups';
const EVENTS_COLLECTION_NAME = 'events';
const REPORTS_COLLECTION_NAME = 'reports';
const PRIVATE_MESSAGES_COLLECTION_NAME = 'privateMessages';

export const usersCollection = () => firebase.firestore().collection(USERS_COLLECTION_NAME);
export const categoriesCollection = () => firebase.firestore().collection(CATEGORIES_COLLECTION_NAME);
export const groupsCollection = () => firebase.firestore().collection(GROUPS_COLLECTION_NAME);
export const eventsCollection = () => firebase.firestore().collection(EVENTS_COLLECTION_NAME);
export const reportsCollection = () => firebase.firestore().collection(REPORTS_COLLECTION_NAME);
export const privateMessagesCollection = () => firebase.firestore().collection(PRIVATE_MESSAGES_COLLECTION_NAME);
