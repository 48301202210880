import { fromJS } from 'immutable';
import {
  CHANGE_STATUS_SUCCESS, ENTER_GROUP_FAILURE, ENTER_GROUP_REQUEST, ENTER_GROUP_SUCCESS,
  LIST_SUCCESS, UPDATE_GROUP_DATA,
} from '../constants/users';

const initialState = fromJS({
  newUser: false,
  loading: true,
  primeiroNome: '',
  ultimoNome: '',
  habilidades: '',
  email: '',
  dataNascimento: '',
  groups: [],
});

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_SUCCESS:
      return action.payload.reduce(
        (newState, user) => newState.setIn(['users', user.id], user),
        state,
      );
    case CHANGE_STATUS_SUCCESS:
      return state
        .setIn(['users', action.payload.id], action.payload);
    case ENTER_GROUP_REQUEST:
      return state
          .set('loading', true);
    case ENTER_GROUP_SUCCESS:
      return  state
          .set('groups', action.payload);
    case ENTER_GROUP_FAILURE:
      return  state
          .set('error', action.payload);
    case UPDATE_GROUP_DATA:
      return state
          .set('groups', action.payload)
          .set('loading', false);
    default:
      return state;
  }
};

export default usersReducer;
