import firebase from "firebase/compat/app";
import {groupsCollection, usersCollection} from "./collections";

export const uploadAndSendImage = async (groupId, result) => {
    try {
        if (!result.cancelled && firebase.auth().currentUser.uid) {
            const messageRef = await groupsCollection()
                .doc(groupId)
                .collection('messages')
                .doc();

            const storageRef = firebase.storage().ref();
            const avatarImageRef = storageRef.child(
                `images/groups/${groupId}/${messageRef.id}.jpg`,
            );
            const response = await fetch(result.uri);
            const blob = await response.blob();

            const uploadTask = avatarImageRef.put(blob);
            uploadTask.on(
                'state_changed',
                () => {
                },
                (error) => {
                    return Promise.reject(error);
                },
                () => {
                    return uploadTask.snapshot.ref.getDownloadURL()
                        .then(
                            async (downloadURL) => sendMessage(groupId, undefined, downloadURL),
                        );
                },
            );
        } else {
            return Promise.reject();
        }
    } catch (e) {
        console.log(e);
        return Promise.reject(e);
    }
};

export const sendMessage = async (groupId, message, imageUrl) => {
    if ((message && message.trim()) || (imageUrl && imageUrl.trim())) {
        const {uid, displayName} = firebase.auth().currentUser;
        const batch = firebase.firestore().batch();

        const groupsRef = await groupsCollection()
            .doc(groupId);
        const userRef = await usersCollection()
            .doc(uid)
            .get();
        const {username} = userRef.data();

        const data = {
            createdAt: new Date(),
            userId: uid,
            name: displayName,
            username,
        };
        if (imageUrl && imageUrl.trim()) {
            data.imageUrl = imageUrl;
        }
        if (message && message.trim()) {
            data.message = message;
        }

        batch.set(groupsRef.collection('messages')
            .doc(), data);
        batch.update(groupsRef, {
            lastMessage: data,
        });
        return await batch.commit()
    }
    return Promise.reject({type: 'Invalid value'})
};

export const deleteMessage = async (groupId, messageId) => {
    return groupsCollection()
        .doc(groupId)
        .collection('messages')
        .doc(messageId)
        .delete()
};

export const startMessagesSnapshot = (groupId, onChange) => {
    const unsubscribe = groupsCollection()
        .doc(groupId)
        .collection('messages')
        .orderBy('createdAt', 'desc')
        .onSnapshot((snapshot) => {
            const allMessages = snapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
                createdAt: doc.data().createdAt.toDate(),
            }));
            onChange(allMessages)
        });
    return () => unsubscribe;
};
