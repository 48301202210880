import { createSelector } from 'reselect';

export const productModuleSelector = state => state.product;

export const selectListedProduct = createSelector(
  productModuleSelector,
  subState => subState.get('product').toJS(),
);

export const selectIsLoading = createSelector(
  productModuleSelector,
  subState => subState.loading,
);
