export const LOGIN_REQUEST = 'AUTH/LOGIN/REQUEST';
export const LOGIN_SUCCESS = 'AUTH/LOGIN/SUCCESS';
export const LOGIN_FAILURE = 'AUTH/LOGIN/FAILURE';

export const LOGOUT_REQUEST = 'AUTH/LOGOUT/REQUEST';
export const LOGOUT_SUCCESS = 'AUTH/LOGOUT/SUCCESS';
export const LOGOUT_FAILURE = 'AUTH/LOGOUT/FAILURE';

export const REGISTRATION_REQUEST = 'AUTH/REGISTRATION/REQUEST';
export const REGISTRATION_SUCCESS = 'AUTH/REGISTRATION/SUCCESS';
export const REGISTRATION_FAILURE = 'AUTH/REGISTRATION/FAILURE';

export const GET_PROFILE_REQUEST = 'AUTH/GET_PROFILE/REQUEST';
export const GET_PROFILE_SUCCESS = 'AUTH/GET_PROFILE/SUCCESS';
export const GET_PROFILE_FAILURE = 'AUTH/GET_PROFILE/FAILURE';

export const SET_AVATAR = 'AUTH/AVATAR/SET';
