export const randomToken = (value, limit) => {
  if (value !== '') {
    let token = '';
    for (let i = 0; i < limit; i++) {
      token += value[Math.floor(Math.random() * value.length)];
    }
    return token;
  }
  return null;
};
