import { createSelector } from 'reselect';

export const chatModuleSelector = state => state.chats;

export const selectListedChats = createSelector(
  chatModuleSelector,
  subState => subState
    .get('chats')
    .valueSeq()
    .filter(val => !!val)
    .toJS(),
);

export const selectOpenChats = createSelector(
  chatModuleSelector,
  subState => subState.get('openChats').valueSeq().toJS(),
);

export const selectCurrentChatId = createSelector(
  chatModuleSelector,
  subState => subState.get('currentId'),
);

export const selectCurrentChat = createSelector(
  chatModuleSelector,
  selectCurrentChatId,
  (subState, id) => {
    const immChat = subState.getIn(['chats', id]);

    return immChat ? immChat.toJS() : null;
  },
);

export const selectSelectedChatId = createSelector(
  chatModuleSelector,
  subState => subState.get('selectedId'),
);

export const selectSelectedChat = createSelector(
  chatModuleSelector,
  selectSelectedChatId,
  (subState, id) => {
    const immChat = subState.getIn(['chats', id]) || subState.getIn(['openChats', id]);

    return immChat ? immChat.toJS() : null;
  },
);

export const selectHasActiveChat = createSelector(
  selectCurrentChat,
  subState => !!subState,
);
