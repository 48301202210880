import {takeLatest, put, call, apply} from 'redux-saga/effects';
import {LIST_REQUEST, CHANGE_STATUS_REQUEST, ENTER_GROUP_REQUEST} from '../constants/users';
import {
  changeStatusFailure,
  changeStatusSuccess,
  listFailure,
  listSuccess,
} from '../actions/users';
import {addGroupToUser, getUserProfile, updateUserProfile} from '../../database/users';
import firebase from "firebase/compat/app";

function* enterGroupRequest({ payload }) {
  try {
    yield call(addGroupToUser, payload);
  } catch (e) {

  }
}

function* changeStatusWatcher({ payload }) {
  try {
    const data = payload;
    yield call(updateUserProfile, data);
  } catch(e) {
    put(changeStatusFailure(e));
  }
}

export default function* usersSaga() {
  yield takeLatest(ENTER_GROUP_REQUEST, enterGroupRequest);
  yield takeLatest(CHANGE_STATUS_REQUEST, changeStatusWatcher);
}
