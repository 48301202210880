import { fromJS } from 'immutable';
import {
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  REGISTRATION_FAILURE,
  REGISTRATION_REQUEST,
  REGISTRATION_SUCCESS,
  SET_AVATAR,
} from '../constants/auth';

const initialState = fromJS({
  isAuthenticated: false,
  newUser: false,
  avatarURL: null,
  displayName: '',
  role: 'user',
  error: null,
});

const getRole = (action) => {
  let role = 'user';

  if (action.payload.roles.indexOf('ROLE_SUPER') > -1) {
    role = 'admin';
  } else if (action.payload.roles.indexOf('ROLE_USER') > -1) {
    role = 'user';
  } else if (action.payload.roles.indexOf('ROLE_ENTERPRISE') > -1) {
    role = 'enterprise';
  }

  return role;
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTRATION_REQUEST:
    case LOGIN_REQUEST:
      return state.set('error', null);
    case REGISTRATION_SUCCESS:
    case LOGIN_SUCCESS:
      return state
        .set('isAuthenticated', true)
        .set('error', null);
    case GET_PROFILE_FAILURE:
    case REGISTRATION_FAILURE:
    case LOGIN_FAILURE:
      return state
        .set('isAuthenticated', false)
        .set('error', action.payload);
    case GET_PROFILE_SUCCESS:
      return state
        .set('id', action.payload.id)
        .set('displayName', action.payload.displayName)
        .set('role', getRole(action));
    case SET_AVATAR:
      return state
        .set('avatarURL', action.payload.url);
    case LOGOUT_REQUEST:
      return state
          .set('error', null)
          .set('isAuthenticated', false);
    case LOGOUT_FAILURE:
      return state
        .set('isAuthenticated', false)
        .set('error', null);
    default:
      return state;
  }
};

export default authReducer;
