import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  REGISTRATION_FAILURE,
  REGISTRATION_REQUEST,
  REGISTRATION_SUCCESS,
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  SET_AVATAR,
} from '../constants/auth';

export const setAvatar = url => ({
  type: SET_AVATAR,
  payload: {
    url,
  },
});

export const loginRequest = payload => ({
  type: LOGIN_REQUEST,
  payload,
});

export const loginSuccess = payload => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const loginFailure = payload => ({
  type: LOGIN_FAILURE,
  payload,
});

export const logoutRequest = payload => ({
  type: LOGOUT_REQUEST,
  payload,
});

export const logoutSuccess = payload => ({
  type: LOGOUT_SUCCESS,
  payload,
});

export const logoutFailure = payload => ({
  type: LOGOUT_FAILURE,
  payload,
});


export const registrationRequest = payload => ({
  type: REGISTRATION_REQUEST,
  payload,
});

export const registrationSuccess = payload => ({
  type: REGISTRATION_SUCCESS,
  payload,
});

export const registrationFailure = payload => ({
  type: REGISTRATION_FAILURE,
  payload,
});

export const getProfileRequest = () => ({
  type: GET_PROFILE_REQUEST,
});

export const getProfileSuccess = payload => ({
  type: GET_PROFILE_SUCCESS,
  payload,
});

export const getProfileFailure = payload => ({
  type: GET_PROFILE_FAILURE,
  payload,
});
