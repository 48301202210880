import { takeLatest, put, call, apply } from 'redux-saga/effects';
import firebase from 'firebase/compat/app';
import {
  GET_PROFILE_REQUEST,
  LOGIN_REQUEST,
  REGISTRATION_REQUEST,
} from '../constants/auth';
import {
  getProfileFailure,
  getProfileRequest,
  loginFailure,
  loginSuccess,
  registrationFailure,
  registrationSuccess,
} from '../actions/auth';
import {createUserProfile, updateUserProfile} from '../../database/users';

function* loginWatcher({ payload }) {
  const auth = firebase.app().auth();

  try {
    let response;
    switch (payload.type) {
      case 'facebook':
        response = yield apply(auth, 'signInWithPopup', [new firebase.auth.FacebookAuthProvider()]);
        break;
      case 'google':
        response = yield apply(auth, 'signInWithPopup', [new firebase.auth.GoogleAuthProvider()]);
        break;
      case 'email':
      default:
        if (!payload.email) throw new Error('Por favor, digite o seu e-mail!');
        if (!payload.pass) throw new Error('Por favor, digite a sua senha!');
        response = yield apply(auth, 'signInWithEmailAndPassword', [payload.email, payload.pass]);
    }

    yield put(loginSuccess(response));
  } catch (e) {
    yield put(loginFailure(e));
  }
}

function* registrationWatcher({ payload }) {
  const auth = firebase.app().auth();

  try {
    if (!payload.email) throw new Error('Por favor, digite o seu e-mail!');
    if (!payload.pass) throw new Error('Por favor, digite a sua senha!');
    let response = yield apply(auth, 'createUserWithEmailAndPassword', [payload.email, payload.pass]);
    
    const user = auth.currentUser;
    
    yield apply(user, 'updateProfile', [{displayName: payload.displayName}]);
    yield call(createUserProfile);

    yield put(registrationSuccess(response));
    // yield call(getProfileRequest)
  } catch (e) {
    yield put(registrationFailure(e.message));
  }
}

function* profileWatcher() {
  try {
    const user = yield call(updateUserProfile);
    console.log(user);
  } catch (e) {
    yield put(getProfileFailure(e));
  }
}

export default function* authSaga() {
  yield takeLatest(LOGIN_REQUEST, loginWatcher);
  yield takeLatest(REGISTRATION_REQUEST, registrationWatcher);
  yield takeLatest(GET_PROFILE_REQUEST, profileWatcher);

  // const token = yield call(cookies.get, 'token');
  // if (token) {
  //   yield call(setAuth, token);
  // }
}