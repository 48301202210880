import {
  LIST_FAILURE,
  LIST_REQUEST,
  LIST_SUCCESS,
} from '../constants/places';

export const listRequest = () => ({
  type: LIST_REQUEST,
});

export const listSuccess = payload => ({
  type: LIST_SUCCESS,
  payload,
});

export const listFailure = payload => ({
  type: LIST_FAILURE,
  payload,
});
